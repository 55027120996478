@use 'src/assets/scss/colors';

.container-circle {
  width: 16px;
  height: 16px;
}

.content-circle {
  width: 8px;
  height: 8px;
}

.widht-poster-style {
  width: 70px;
}

.container-circle-primary {
  background: lighten(colors.$snackbar-primary, 30%);
}

.circle-primary {
  background: colors.$snackbar-primary;
}

.container-circle-success {
  background: lighten(colors.$snackbar-success, 30%);
}

.circle-success {
  background: colors.$snackbar-success;
}

.container-circle-danger {
  background: lighten(colors.$snackbar-danger, 30%);
}

.circle-danger {
  background: colors.$snackbar-danger;
}

.container-circle-warning {
  background: lighten(colors.$snackbar-warning, 30%);
}

.circle-warning {
  background: colors.$snackbar-warning;
}

.container-circle-secondary {
  background: lighten(colors.$snackbar-secondary, 30%);
}

.circle-secondary {
  background: colors.$snackbar-secondary;
}

.container-circle-info {
  background: lighten(colors.$snackbar-info, 30%);
}

.circle-info {
  background: colors.$snackbar-info;
}

.container-circle-light {
  background: lighten(colors.$snackbar-light, 10%);
}

.circle-light {
  background: colors.$snackbar-light;
}

.container-circle-dark {
  background: lighten(colors.$snackbar-dark, 40%);
}

.circle-dark {
  background: colors.$snackbar-dark;
}

.circle-enable-rol {
  color: colors.$theme-primary-700;
}

.circle-disable-rol {
  color: lighten(colors.$text-disabled, 10%) !important;
  cursor: auto !important;
}

.text-read {
  font-size: 12px;
  font-weight: 500;
}

.circle-read {
  height: 22px;
  width: 22px;
  color: lighten(colors.$qbono-one, 65%);
  background: lighten(colors.$qbono-one, 25%);
}

.circle-avatar {
  height: 32px;
  width: 32px;
  background: colors.$theme-primary-700;
}

.circle-icon {
  color: colors.$snackbar-secondary;
  background-color: lighten(colors.$snackbar-primary, 40%);
}
